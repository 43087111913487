import React from 'react'
import './Footer.css'

const Footer = () => {
    return (
        <footer className="footer" >
            <span>R2S Version v2.2.0 (Smart Service Queensland ap-southeast-2) </span>
        </footer>
    )
}

export default Footer