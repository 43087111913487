import React, { useState, useEffect } from 'react'
import {MultiSelect} from "react-multi-select-component"
import moment from 'moment'
import dayjs from 'dayjs'
import utils from '../../services/utils'
import constants, { SEARCH_PARAM_DEPARTMENT } from '../../constants'
import './ConversationsSearchCriteria.css'
import { DatePicker } from 'antd';
import AlertModal from '../Modals/alertModal/AlertModal'
const { RangePicker } = DatePicker;

const ConversationsSearchCriteria = (props) => {
    const [error, setError] = useState()
    const [alertHeader, setAlertHeader] = useState("Error")

    const { divisions, agents,convDirection, callTypes} = props
    const [dnis, setDNIS] = useState()
    const [ani, setANI] = useState()
    const [conversationID, setConversationID] = useState()
    const [connId, setConnId] = useState()
    const [callDuration, setCallDuration] = useState()
    const [callDurationOperator, setCallDurationOperator] = useState('EQ')

    const [holdTime, setHoldTime] = useState()
    const [holdTimeOperator, setHoldTimeOperator] = useState('EQ')

    const [holdCountOperator, setHoldCountOperator] = useState('EQ')
    const [holdCount, setHoldCount] = useState()

    const [transferCountOperator, setTransferCountOperator] = useState('EQ')
    const [transferCount, setTransferCount] = useState()

    const [conferenceCountOperator, setConferenceCountOperator] = useState('EQ')
    const [conferenceCount, setConferenceCount] = useState()

    const [selectedDivisions, setSelectedDivisions] = useState([])    
    const [selectedAgents, setSelectedAgents] = useState([])
    const [selectedCallTypes, setSelectedCallTypes] = useState([])
    
    const [selectedConvDirection, setSelectedConvDirection] = useState([])    
    const [dateRange, setdateRange] = useState([]); // 0th position - startDate , 1st position - end Date

    const dateFormat = 'MM/DD/YYYY h:mm A';

    function onSelectDate(dateTimeRange, dateRange) {
        console.log(`onSelectDate value is ${JSON.stringify(dateRange)}, ${JSON.stringify(dateTimeRange)}`);
        setdateRange(dateRange);
    }
    // init from session storage
    useEffect(() => {
        try {            
            const searchCriteriaTemp = JSON.parse(sessionStorage.getItem(constants.R2S_SEARCH_CRITERIA));
            console.log(`ConversationsSearchCriteria:::: got search criteria as ${JSON.stringify(searchCriteriaTemp)}`);
            if(searchCriteriaTemp){
                try {
                    if(searchCriteriaTemp.startDateUTC && searchCriteriaTemp.endDateUTC) {
                        console.log(`Setting Date range as ${searchCriteriaTemp.startDateUTC} - ${searchCriteriaTemp.endDateUTC}`);
                        setdateRange([searchCriteriaTemp.startDateUTC.split("T")[0], searchCriteriaTemp.endDateUTC.split("T")[0]]);
                    }

                    if(searchCriteriaTemp.agent){
                        console.log(`Setting Agent as ${searchCriteriaTemp.agent.split(',')}`);
                        let tempCriteria ;
                        let selectedAgentTemp ;
                        if(searchCriteriaTemp.agent == '*'){
                            selectedAgentTemp = agents.filter((x => x.value!== '*'));
                        }else{
                            tempCriteria = searchCriteriaTemp.agent.split('|');
                            selectedAgentTemp = agents.filter((x => tempCriteria.includes(x.value)));
                        }
                        console.log(`Setting Agent as ${JSON.stringify(selectedAgentTemp)}`);
                        setSelectedAgents(selectedAgentTemp);
                    }

                    if(searchCriteriaTemp.convDirection){
                        console.log(`Setting convDirection as ${searchCriteriaTemp.convDirection}`);
                        if(searchCriteriaTemp.convDirection == '*'){
                            setSelectedConvDirection(convDirection.filter((x => x.value !== '*')));
                        } else{
                            setSelectedConvDirection(convDirection.filter((x => x.value == searchCriteriaTemp.convDirection)));
                        }
                    }

                    if(searchCriteriaTemp.division){
                        console.log(`Setting division as ${searchCriteriaTemp.division}`);
                        let tempCriteria ;
                        let selectedDivisionsTemp ;
                        if(searchCriteriaTemp.division == '*'){
                            selectedDivisionsTemp = divisions.filter((x => x.value!== '*'));
                        }else{
                            tempCriteria = searchCriteriaTemp.division.split('|');
                            selectedDivisionsTemp = divisions.filter((x => tempCriteria.includes(x.value)));
                        }
                        console.log(`Setting division as ${JSON.stringify(selectedDivisionsTemp)}`);
                        setSelectedDivisions(selectedDivisionsTemp);
                    }

                    if(searchCriteriaTemp.callDuration){
                        setCallDuration(searchCriteriaTemp.callDuration.value);
                        setCallDurationOperator(searchCriteriaTemp.callDuration.operator);
                    }
                    
                    if(searchCriteriaTemp.holdTime){
                        setHoldTime(searchCriteriaTemp.holdTime.value);
                        setHoldTimeOperator(searchCriteriaTemp.holdTime.operator);
                    }

                    if(searchCriteriaTemp.holdCount){
                        setHoldCount(searchCriteriaTemp.holdCount.value);
                        setHoldCountOperator(searchCriteriaTemp.holdCount.operator);
                    }
                    
                    if(searchCriteriaTemp.transferCount){
                        setTransferCount(searchCriteriaTemp.transferCount.value);
                        setTransferCountOperator(searchCriteriaTemp.transferCount.operator);
                    }

                    if(searchCriteriaTemp.conferenceCount){
                        setConferenceCount(searchCriteriaTemp.conferenceCount.value);
                        setConferenceCountOperator(searchCriteriaTemp.conferenceCount.operator);
                    }

                    if(searchCriteriaTemp.ani){
                        setANI(searchCriteriaTemp.ani);
                    }

                    if(searchCriteriaTemp.dnis){
                        setDNIS(searchCriteriaTemp.dnis);
                    }
                    
                    if(searchCriteriaTemp.conversationID){
                        setConversationID(searchCriteriaTemp.conversationID);
                    }

                    if(searchCriteriaTemp.connId){
                        setConnId(searchCriteriaTemp.connId)
                    }

                    if(searchCriteriaTemp.callType){
                        console.log(`Setting calltype as ${searchCriteriaTemp.callType}`);
                        let tempCriteria ;
                        let selectedCallTypesTemp ;
                        if(searchCriteriaTemp.callType == '*'){
                            selectedCallTypesTemp = callTypes.filter((x => x.value !== '*'));
                        } else {
                            tempCriteria = searchCriteriaTemp.callType.split('|');
                            selectedCallTypesTemp = callTypes.filter((x => tempCriteria.includes(x.value)));
                        }
                        console.log(`Setting calltypes as ${JSON.stringify(selectedCallTypesTemp)}`);
                        setSelectedCallTypes(selectedCallTypesTemp);
                    }
                } catch (error) {
                     console.log(error);
                }
            }
            if (!searchCriteriaTemp) return;

        } catch (error) {
            console.log('conversationsSearchCriteria.useeffect.error', error)
        }
    }, [])

    const search = async () => {
        if (!dateRange[0] || !dateRange[1]){
            setError(`Interval start and end dates are mandatory`);
            setAlertHeader("Validation Error");
            return;
        }

        
        if(callDuration && callDuration<0) {
            setError(`Call Duration cannot be less than 0`);
            setAlertHeader("Validation Error");
            return;
        }
        if(holdTime && holdTime < 0){
            setError(`Hold Time cannot be less than 0`);
            setAlertHeader("Validation Error");
            return;
        }
        if(holdCount && holdCount < 0){
            setError(`No. of Holds cannot be less than 0`);
            setAlertHeader("Validation Error");
            return;
        }
        if(transferCount && transferCount < 0){
            setError(`No. of Transfers cannot be less than 0`);
            setAlertHeader("Validation Error");
            return;
        }
        if(conferenceCount && conferenceCount < 0){
            setError(`No. of Conferences cannot be less than 0`);
            setAlertHeader("Validation Error");
            return;
        }

        console.log('Searching...')
        const searchCriteria = new Map()
        if (dateRange[0]){
            let tempStartDate = moment(dateRange[0], dateFormat);
            searchCriteria.set(constants.SEARCH_PARAM_STARTDT_UTC, dateRange[0]);
            searchCriteria.set(constants.SEARCH_PARAM_STARTDT, tempStartDate.toISOString());
        } 

        if (dateRange[1]){
            let tempEndDate = moment(dateRange[1], dateFormat);
            searchCriteria.set(constants.SEARCH_PARAM_ENDDT_UTC, dateRange[1] );
            searchCriteria.set(constants.SEARCH_PARAM_ENDDT, tempEndDate.toISOString() );
        }     
        if(selectedDivisions){
            console.log(`Selected Divisions are ${JSON.stringify(selectedDivisions)}`)
            //searchCriteria.set('selectedDivisions', selectedDivisions)
            if (selectedDivisions.length === divisions.length) // all
                searchCriteria.set(constants.SEARCH_PARAM_DIVISION, '*')
            else
                searchCriteria.set(constants.SEARCH_PARAM_DIVISION, decodeURIComponent(selectedDivisions.map(x => x.value).join('|')));
        }

        

        if(selectedAgents){
            if (selectedAgents.length === agents.length) // all
                searchCriteria.set(constants.SEARCH_PARAM_AGENT, '*');
            else{                
                searchCriteria.set(constants.SEARCH_PARAM_AGENT, decodeURIComponent(selectedAgents.map(x => x.value).join('|')));
            }
                
        }

        if(selectedConvDirection){
            //searchCriteria.set('selectedAgents', selectedAgents);
            if (selectedConvDirection.length === convDirection.length) // all
                searchCriteria.set(constants.SEARCH_PARAM_DIRECTION, '*');
            else
                searchCriteria.set(constants.SEARCH_PARAM_DIRECTION, decodeURIComponent(selectedConvDirection.map(x => x.value)));
        }
        if (ani) searchCriteria.set(constants.SEARCH_PARAM_ANI, decodeURIComponent(ani));
        if (dnis) searchCriteria.set(constants.SEARCH_PARAM_DNIS, decodeURIComponent(dnis));
        if (conversationID) searchCriteria.set(constants.SEARCH_PARAM_CONVERSATIONID, conversationID);

        if(connId)searchCriteria.set(constants.SEARCH_PARAM_CONNID, connId);
        
        if(selectedCallTypes){
            if (selectedCallTypes.length === callTypes.length) // all
                searchCriteria.set(constants.SEARCH_PARAM_CALL_TYPE, '*');
            else{                
                searchCriteria.set(constants.SEARCH_PARAM_CALL_TYPE, decodeURIComponent(selectedCallTypes.map(x => x.value).join('|')));                
            }
        }
        
        if(callDuration)searchCriteria.set(constants.SEARCH_PARAM_CALL_DURATION, {value:callDuration,operator: callDurationOperator});
        if(holdTime)searchCriteria.set(constants.SEARCH_PARAM_HOLD_TIME, {value:holdTime,operator: holdTimeOperator});
        if(holdCount)searchCriteria.set(constants.SEARCH_PARAM_HOLD_COUNT, {value:holdCount,operator: holdCountOperator});
        if(transferCount)searchCriteria.set(constants.SEARCH_PARAM_TRANSFER_COUNT, {value:transferCount,operator: transferCountOperator});
        if(conferenceCount)searchCriteria.set(constants.SEARCH_PARAM_CONFERENCE_COUNT, {value:conferenceCount,operator: conferenceCountOperator});

        props.search(utils.mapToObj(searchCriteria))
        sessionStorage.setItem(constants.R2S_SEARCH_CRITERIA, JSON.stringify(utils.mapToObj(searchCriteria)))
    }
    const cancel = async () => {
        console.log('canceling...')
        props.cancel()
    }

    return (
        <div className="search-wrap pl-2 pb-2">
            <AlertModal isOpen={!!error} header={alertHeader} toggle={() => { setError(undefined) }} body={error} />
            <div className='row justify-content-start'>                
                <div className='col col-12 col-xl-3' >
                    <div className="input-group">
                        <div className="input-group-prepend">
                            <span className="input-group-text">Division</span>
                        </div>
                        <MultiSelect className="multi-select division-multi-select"
                            options={divisions}
                            value={selectedDivisions}
                            onChange={e => { setSelectedDivisions(e) }}
                            labelledBy="Select"
                        />
                    </div>
                </div>                
                <div className='col col-12 col-xl-3'>
                <div className="input-group">
                    <div className="input-group-prepend">
                            <span className="input-group-text">Direction of the Call</span>
                        </div>
                        <MultiSelect className="multi-select direction-multi-select"
                            options={convDirection}
                            value={selectedConvDirection}
                            onChange={e => { setSelectedConvDirection(e) }}
                            labelledBy="Select"
                        />
                        
                    </div>
                </div>
            </div>

            <div className='row justify-content-start'>
            </div>

            <div className='row justify-content-start'>
                <div className='col col-12 col-xl-3'>
                <div className="input-group">
                    <div className="input-group-prepend">
                            <span className="input-group-text">Agent Name</span>
                        </div>
                        <MultiSelect className="multi-select agents-multi-select"
                            options={agents}
                            value={selectedAgents}
                            onChange={e => { setSelectedAgents(e) }}
                            labelledBy="Select"
                        />
                    </div>
                </div>
                <div className='col col-12 col-xl-3'>
                    <div className="input-group">
                        <div className="input-group-prepend">
                            <span className="input-group-text">ANI</span>
                        </div>
                        <input className='form-control conversations-custom-control' type='text' value={ani}
                            onChange={e => setANI(e.target.value)} />
                    </div>
                </div>
                <div className='col col-12 col-xl-3'>
                    <div className="input-group">
                        <div className="input-group-prepend">
                            <span className="input-group-text">DNIS</span>
                        </div>
                        <input className='form-control conversations-custom-control' type='text' value={dnis}
                            onChange={e => setDNIS(e.target.value)} />
                    </div>
                </div>
            </div>
            <div className='row justify-content-start'>
                <div className='col col-12 col-xl-3'>
                    <div className="input-group">
                        <div className="input-group-prepend">
                            <span className="input-group-text">CONNID</span>
                        </div>
                        <input className='form-control conversations-custom-control' type='text' value={connId}
                            onChange={e => setConnId(e.target.value)} />
                    </div>
                </div>
                <div className='col col-12 col-xl-3'>
                <div className="input-group">
                    <div className="input-group-prepend">
                            <span className="input-group-text">Call Type</span>
                        </div>
                        <MultiSelect className="multi-select agents-multi-select"
                            options={callTypes}
                            value={selectedCallTypes}
                            onChange={e => { setSelectedCallTypes(e) }}
                            labelledBy="Select"
                        />
                    </div>
                </div>
                <div className='col col-12 col-xl-3'>
                    <div className="input-group">
                        <div className="input-group-prepend">
                            <span className="input-group-text">Call Duration</span>
                        </div>
                        <select value={callDurationOperator} onChange={e => setCallDurationOperator(e.target.value)}>
                            <option value="LT">&lt;</option>
                            <option value="EQ">=</option>
                            <option value="GT">&gt;</option>
                        </select>
                        <input className='form-control conversations-custom-control margin-left-ssq' type='number' value={callDuration}
                            onChange={e => setCallDuration(e.target.value)} />
                    </div>
                </div>
                <div className='col col-12 col-xl-3'>
                    <div className="input-group">
                        <div className="input-group-prepend">
                            <span className="input-group-text">Hold Time</span>
                        </div>
                        <select value={holdTimeOperator} onChange={e => setHoldTimeOperator(e.target.value)} >
                            <option value="LT">&lt;</option>
                            <option value="EQ">=</option>
                            <option value="GT">&gt;</option>
                        </select>
                        <input className='form-control conversations-custom-control margin-left-ssq' type='number' value={holdTime}
                            onChange={e => setHoldTime(e.target.value)} />
                    </div>
                </div>
            </div>
            <div className='row justify-content-start'>
            <div className='col col-12 col-xl-3'>
                    <div className="input-group">
                        <div className="input-group-prepend">
                            <span className="input-group-text">No. of Holds</span>
                        </div>
                        <select value={holdCountOperator} onChange={e => setHoldCountOperator(e.target.value)} >
                            <option value="LT">&lt;</option>
                            <option value="EQ">=</option>
                            <option value="GT">&gt;</option>
                        </select>
                        <input className='form-control conversations-custom-control margin-left-ssq' type='number' value={holdCount}
                            onChange={e => setHoldCount(e.target.value)} />
                    </div>
                </div>
                <div className='col col-12 col-xl-3'>
                    <div className="input-group">
                        <div className="input-group-prepend">
                            <span className="input-group-text">No. of Transfers</span>
                        </div>
                        <select value={transferCountOperator} onChange={e => setTransferCountOperator(e.target.value)} >
                            <option value="LT">&lt;</option>
                            <option value="EQ">=</option>
                            <option value="GT">&gt;</option>
                        </select>
                        <input className='form-control conversations-custom-control margin-left-ssq' type='number' value={transferCount}
                            onChange={e => setTransferCount(e.target.value)} />
                    </div>
                </div>
                <div className='col col-12 col-xl-3'>
                    <div className="input-group">
                        <div className="input-group-prepend">
                            <span className="input-group-text">No. of Conferences</span>
                        </div>
                        <select value={conferenceCountOperator} onChange={e => setConferenceCountOperator(e.target.value)} >
                            <option value="LT">&lt;</option>
                            <option value="EQ">=</option>
                            <option value="GT">&gt;</option>
                        </select>
                        <input className='form-control conversations-custom-control margin-left-ssq' type='number' value={conferenceCount}
                            onChange={e => setConferenceCount(e.target.value)} />
                    </div>
                </div>
            </div>
            <div className='row justify-content-start'>
                             
                <div className='col col-12 col-xl-3'>
                    <div className="input-group">
                        <div className="input-group-prepend">
                            <span className="input-group-text">Conversation ID</span>
                        </div>
                        <input className='form-control conversations-custom-control' type='text' value={conversationID}
                            onChange={e => setConversationID(e.target.value)} />
                    </div>
                </div>
                <div className='col col-12 col-xl-auto'>
                    <div className="input-group">
                            <div className="input-group-prepend">
                                <span className="input-group-text">Interval *</span>
                            </div>
                            <RangePicker size="large" aria-required placement="bottomLeft" format={dateFormat} allowClear={false} style={{ borderRadius: "5px" }} key={new Date().getTime()} defaultValue={ dateRange.length ? [dayjs(dateRange[0], dateFormat), dayjs(dateRange[1], dateFormat)] : null } showTime onChange={onSelectDate} />
                    </div>                   
                </div>   
            </div>
            <br />
            <div className='row justify-content-center d-flex flex-row-reverse bd-highlight'>
                <div className='col col-2 '>
                    <button type="button" className="btn btn-primary btn-space" onClick={search}>Search</button>
                    <button type="button" className="btn btn-secondary btn-space" onClick={cancel}>Cancel</button>
                </div>
            </div>
        </div>

    )


}

export default ConversationsSearchCriteria