import React, { useState, useEffect } from 'react'
import ReactTable from "react-table-6";
import { Collapse, Tooltip } from 'reactstrap'
import Header from '../header/Header'
import Footer from '../footer/Footer';
import DownloadSearchCriteria from '../downloadSearchCriteria/DownloadSearchCriteria'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFilter, faCopy, faDownload } from '@fortawesome/free-solid-svg-icons'
import backend from '../../services/backend'
import AlertMessage from '../alertMessage/AlertMessage'
import WaitingModal from '../Modals/waitingModal/WaitingModal'
import AlertModal from '../Modals/alertModal/AlertModal';
import moment from 'moment'
import utils from '../../services/utils'
import constants from '../../constants'
import './download.css'

const Download = (props) => {
    const [clients, setClients] = useState(undefined)
    const [actions, setActions] = useState(undefined)
    const [users, setUsers] = useState(undefined)
    const [searchAlertVisible, setSearchAlertVisible] = useState(false)
    const [searchAlertMessage, setSearchAlertMessage] = useState()
    const [audit, setAudit] = useState([])
    const [isWaitingModalOpen, setIsWaitingModalOpen] = useState(false)
    const [error, setError] = useState()
    const [waitingHeader, setWaitingHeader] = useState()
    const [role, setRole] = useState()
    const recordingStatus = {
        value:"Available",
        value:"Expired",
        value:"In Progress"
    }

    // Collapsable
    const [isSearchCriteriaOpen, setIsSearchCriteriaOpen] = useState(true)

    // Tooltips
    const [searchExportStatusTooltipOpen, setSearchAccessReportTooltipOpen] = useState(false)
    const [downloadTooltipOpen, setDownloadTooltipOpen] = useState(false)

    useEffect(() => { // initial load
        const fetchData = async () => {
            setRole(sessionStorage.getItem(constants.R2S_USER_ROLE));
            try {
                setWaitingHeader('Loading Downloads');
                setIsWaitingModalOpen(true);
                setIsSearchCriteriaOpen(true);
            } catch (error) {
                setError(`An error occured while fetching users:${JSON.stringify(error.message)}`)
                console.log(error)
            } finally {
                setIsWaitingModalOpen(false)
            }
        }
        fetchData()
    }, [])

    const fetchAudit = async (searchCriteria) => {
        try {
            setWaitingHeader('Loading audit')
            setIsWaitingModalOpen(true)
            const response = await backend.exportStatus(sessionStorage.getItem(constants.R2S_TOKEN), searchCriteria)
            // console.log('fetchData.response:', response)
            if (response.records_returned < response.total_records) {
                setSearchAlertVisible(true)
                setSearchAlertMessage(`Showing ${response.records_returned} of ${response.total_records} records. To find specific set of results, please refine your search criteria.`)
            }
            setAudit(response.exportStatus)
            console.log(`Audit Search Response are ${JSON.stringify(response.exportStatus)}`);
            sessionStorage.setItem(constants.SESSION_KEY_AUDIT, JSON.stringify(response.exportStatus))

        } catch (error) {
            setError(`An error occured while fetching audit access report:${JSON.stringify(error.message)}`)
            console.log(error)
        } finally {
            setIsWaitingModalOpen(false)
        }
    }

    const search = async (searchCriteria) => {
        console.log(`AccessReport.searchCriteria: ${JSON.stringify(searchCriteria)}`);
        fetchAudit(searchCriteria)
        toggleSearchCriteria(false)
        console.log(JSON.stringify(audit));
    }

    const toggleSearchCriteria = () => setIsSearchCriteriaOpen(!isSearchCriteriaOpen)

    const DownloadReport = async () => {
        utils.JSONToCSVConvertor(audit, `R2S_AccessReport_${moment().format()}`, true)
    }

    return (
        <div id="component-wrap">
            <Header role={props.role} />
            <div id="component-toolbar">
                <button id="searchExportStatus" onClick={toggleSearchCriteria}><FontAwesomeIcon className="fa-2x" icon={faFilter} /></button>
            </div>
            <WaitingModal
                isOpen={isWaitingModalOpen}
                header={waitingHeader}
            />
            <Tooltip placement="bottom" isOpen={searchExportStatusTooltipOpen}
                target="searchExportStatus"
                toggle={() => { setSearchAccessReportTooltipOpen(!searchExportStatusTooltipOpen) }}>
                Define access report criteria
            </Tooltip>
            <AlertModal
                isOpen={!!error}
                header='Error'
                toggle={() => { setError(undefined) }}
                body={error}
            />
            <AlertMessage
                message={searchAlertMessage}
                visible={searchAlertVisible}
                setVisible={x => { setSearchAlertVisible(x) }}
            />
            <Collapse isOpen={isSearchCriteriaOpen}>
                
                    <DownloadSearchCriteria
                        search={search}
                        cancel={() => { toggleSearchCriteria(false) }}                        
                    />
            </Collapse> 
            <ReactTable
                showPageSizeOptions={true}
                pageSizeOptions={[5, 10, 15, 20, 25, 50, 100]}
                defaultPageSize={15}
                minRows={0}
                filterable
                className="-highlight -striped table-container"
                data={audit}
                columns={[{
                    Header: 'Submitted Date',
                    accessor: 'submitteddate',
                    headerClassName: 'headerColumn',
                    Cell: row => {
                        return (
                            <div className="overflow div-center">
                                {moment(row.value).format("MMM Do YYYY, h:mm:ss a Z")}
                            </div>
                        )
                    },
                    filterable: false
                },
                {
                    Header: 'Submitted By',
                    accessor: 'email',
                    headerClassName: 'headerColumn',
                    Cell: row => {
                        return (
                            <div className="overflow div-center">
                                {row.value}
                            </div>
                        )
                    }
                },
                {
                    Header: 'Export Criteria',
                    accessor: 'criteria',
                    headerClassName: 'headerColumn',
                    Cell: row => {
                        return (
                            <div className="overflow div-center">
                                {JSON.stringify(row.value)}
                            </div>
                        )
                    }
                },
                {
                    Header: 'Expires at',
                    accessor: 'expiryTimeStamp',
                    headerClassName: 'headerColumn',
                    Cell: row => {
                        return (
                            <div className="overflow div-center">
                                {moment(row.value).format("MMM Do YYYY, h:mm:ss a Z")}
                            </div>
                        )
                    },
                    filterable: false
                },
                {
                    Header: 'Status',
                    accessor: 'jobstatus',
                    headerClassName: 'headerColumn',
                    Cell: row => {
                        return (
                            <div className="overflow div-center">
                                {row.value}
                            </div>
                        )
                    }
                },
                {
                    Header: 'Download Link',
                    accessor: 'link',
                    headerClassName: 'headerColumn',
                    Cell: row => {
                        return (
                            <div className="div-center overflow">
                                <a href={row.original.link} target="_blank" rel="noopener noreferrer" >Download</a>   
                            </div>
                        )
                    },
                    filterable: false
                }
                ]}
                getTdProps={(state, rowInfo, column, instance) => {
                    return {
                        style: {
                            cursor: 'pointer'
                        }
                    }
                }}
                getTrProps={() => {
                    return {
                        style: {
                            flex: '0 0 auto'
                        }
                    }
                }}
                getTrGroupProps={() => {
                    return {
                        style: {
                            flex: '0 0 auto'
                        }
                    }
                }}
                defaultFilterMethod={(filter, row) => {
                    const id = filter.pivotId || filter.id;
                    return (row[id] !== undefined ?
                        String(row[id].toString().toLowerCase()).startsWith(filter.value.toLowerCase())
                        : true
                    );
                }}
            />
            <Footer />
        </div>)
}



export default Download